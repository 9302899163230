import {
	Stage,
	WasteGuideState,
	LOT_SIZE_STAGE,
	WASTE_VOLUME_FOR_MEDIUM_LOT_STAGE,
	WASTE_VOLUME_FOR_LARGE_LOT_STAGE,
	FREQUENT_WASTE_FOR_MEDIUM_COMPANY_STAGE,
	FREQUENT_WASTE_FOR_LARGE_COMPANY_STAGE,
	INCIDENTAL_WASTE_STAGE,
	ALWAYS_SEPARATE_WASTE_STAGE,
	RESULT_STAGE_FOR_SMALL_LOT,
	RESULT_STAGE_FOR_SMALL_VOLUME,
	RESULT_STAGE_FOR_MEDIUM_LOT,
	RESULT_STAGE_FOR_LARGE_LOT_AND_MEDIUM_VOLUME,
	RESULT_STAGE_FOR_LARGE_LOT_AND_LARGE_VOLUME,
	ANSWERS,
	firstQuestionStage,
	lastQuestionStage,
	resultStages,
} from '../wasteGuide.types';

import { Draft } from '@/globals/helpers/immer';

export const includesResultStage = (stages: Array<Stage>) =>
	stages.some((stage) => resultStages.includes(stage));

export const findResultStage = (stages: Array<Stage>) =>
	stages.find((stage) => resultStages.includes(stage));

export const startWasteGuide = (wasteGuideStateDraft: WasteGuideState) => {
	wasteGuideStateDraft.stages = [firstQuestionStage];
};

const getNextStateAfterLotSize = (lotSize: WasteGuideState['lotSize']) => {
	switch (lotSize) {
		case ANSWERS.LOT_SIZE.SMALL:
			return INCIDENTAL_WASTE_STAGE;
		case ANSWERS.LOT_SIZE.MEDIUM:
			return WASTE_VOLUME_FOR_MEDIUM_LOT_STAGE;
		case ANSWERS.LOT_SIZE.LARGE:
			return WASTE_VOLUME_FOR_LARGE_LOT_STAGE;
		default:
			throw new Error(`Unknown lotSize: ${lotSize}`);
	}
};

const getNextStateAfterWasteVolumeForMediumLot = (
	wasteGuideState: WasteGuideState,
) => {
	const { wasteVolumeForMediumLot, lotSize } = wasteGuideState;
	if (lotSize !== ANSWERS.LOT_SIZE.MEDIUM) {
		throw new Error(
			`Answer (${wasteVolumeForMediumLot}) for wasteVolumeForMediumLot but lotsize is not medium`,
		);
	}

	switch (wasteVolumeForMediumLot) {
		case ANSWERS.WASTE_VOLUME.INCIDENTAL:
			return INCIDENTAL_WASTE_STAGE;
		case ANSWERS.WASTE_VOLUME.FREQUENTLY_FOR_MEDIUM_LOT:
			return FREQUENT_WASTE_FOR_MEDIUM_COMPANY_STAGE;
		default:
			throw new Error(
				`Unknown wasteVolumeForMediumLot: ${wasteVolumeForMediumLot}`,
			);
	}
};

const getNextStateAfterWasteVolumeForLargeLot = ({
	wasteVolumeForLargeLot,
	lotSize,
}: WasteGuideState) => {
	if (lotSize !== ANSWERS.LOT_SIZE.LARGE) {
		throw new Error(
			`Answer (${wasteVolumeForLargeLot}) for wasteVolumeForMediumLot but lotsize is not medium`,
		);
	}

	switch (wasteVolumeForLargeLot) {
		case ANSWERS.WASTE_VOLUME.INCIDENTAL:
			return INCIDENTAL_WASTE_STAGE;
		case ANSWERS.WASTE_VOLUME.SEMI_FREQUENTLY_FOR_LARGE_LOT:
			return FREQUENT_WASTE_FOR_MEDIUM_COMPANY_STAGE;
		case ANSWERS.WASTE_VOLUME.FREQUENTLY_FOR_LARGE_LOT:
			return FREQUENT_WASTE_FOR_LARGE_COMPANY_STAGE;
		default:
			throw new Error(
				`Unknown wasteVolumeForLargeLot: ${wasteVolumeForLargeLot}`,
			);
	}
};

const getNextStateAfterFrequentWasteForMediumCompany = (
	frequentWasteForMediumCompany: WasteGuideState['frequentWasteForMediumCompany'],
): Stage => {
	// this value should be set, since it is a required radio btn
	if (frequentWasteForMediumCompany == null) {
		throw new Error(
			`Missing answer for question in stage ${FREQUENT_WASTE_FOR_MEDIUM_COMPANY_STAGE}`,
		);
	}
	return INCIDENTAL_WASTE_STAGE;
};

const getCurrentWasteVolume = ({
	stages,
	wasteVolumeForMediumLot,
	wasteVolumeForLargeLot,
}: WasteGuideState) => {
	if (stages.includes(WASTE_VOLUME_FOR_MEDIUM_LOT_STAGE)) {
		return wasteVolumeForMediumLot;
	}
	if (stages.includes(WASTE_VOLUME_FOR_LARGE_LOT_STAGE)) {
		return wasteVolumeForLargeLot;
	}
	return null;
};

const getNextStateAfterAlwaysSeparateWaste = (
	wasteGuideState: WasteGuideState,
) => {
	const { lotSize } = wasteGuideState;
	if (lotSize === ANSWERS.LOT_SIZE.SMALL) {
		return RESULT_STAGE_FOR_SMALL_LOT;
	}

	const wasteVolume = getCurrentWasteVolume(wasteGuideState);

	if (wasteVolume === ANSWERS.WASTE_VOLUME.INCIDENTAL) {
		return RESULT_STAGE_FOR_SMALL_VOLUME;
	}

	if (lotSize === ANSWERS.LOT_SIZE.MEDIUM) {
		return RESULT_STAGE_FOR_MEDIUM_LOT;
	}

	if (
		lotSize === ANSWERS.LOT_SIZE.LARGE &&
		wasteVolume === ANSWERS.WASTE_VOLUME.SEMI_FREQUENTLY_FOR_LARGE_LOT
	) {
		return RESULT_STAGE_FOR_LARGE_LOT_AND_MEDIUM_VOLUME;
	}

	if (
		lotSize === ANSWERS.LOT_SIZE.LARGE &&
		wasteVolume === ANSWERS.WASTE_VOLUME.FREQUENTLY_FOR_LARGE_LOT
	) {
		return RESULT_STAGE_FOR_LARGE_LOT_AND_LARGE_VOLUME;
	}

	throw new Error(
		`Could nog find result stage for lotSize and wasteVolume: ${lotSize}, ${wasteVolume}`,
	);
};

export const goToNextStage = (wasteGuideStateDraft: Draft<WasteGuideState>) => {
	switch (wasteGuideStateDraft.stages.at(-1)) {
		case LOT_SIZE_STAGE: {
			const { lotSize } = wasteGuideStateDraft;
			wasteGuideStateDraft.stages.push(getNextStateAfterLotSize(lotSize));
			return;
		}
		case WASTE_VOLUME_FOR_MEDIUM_LOT_STAGE: {
			wasteGuideStateDraft.stages.push(
				getNextStateAfterWasteVolumeForMediumLot(wasteGuideStateDraft),
			);
			return;
		}
		case WASTE_VOLUME_FOR_LARGE_LOT_STAGE: {
			wasteGuideStateDraft.stages.push(
				getNextStateAfterWasteVolumeForLargeLot(wasteGuideStateDraft),
			);
			return;
		}

		case FREQUENT_WASTE_FOR_MEDIUM_COMPANY_STAGE: {
			const { frequentWasteForMediumCompany } = wasteGuideStateDraft;

			wasteGuideStateDraft.stages.push(
				getNextStateAfterFrequentWasteForMediumCompany(
					frequentWasteForMediumCompany,
				),
			);
			return;
		}

		case FREQUENT_WASTE_FOR_LARGE_COMPANY_STAGE:
			wasteGuideStateDraft.stages.push(INCIDENTAL_WASTE_STAGE);

			return;
		case INCIDENTAL_WASTE_STAGE:
			return wasteGuideStateDraft.stages.push(ALWAYS_SEPARATE_WASTE_STAGE);

		case ALWAYS_SEPARATE_WASTE_STAGE: {
			return wasteGuideStateDraft.stages.push(
				getNextStateAfterAlwaysSeparateWaste(wasteGuideStateDraft),
			);
		}
		default:
			return wasteGuideStateDraft;
	}
};

export const goBackToStage = (
	wasteGuideStateDraft: Draft<WasteGuideState>,
	stageName: Stage,
) => {
	const stageIndex = wasteGuideStateDraft.stages.indexOf(stageName);

	if (stageIndex < 0) {
		throw new Error(
			`Stage not found in stage list: ${stageName} in ${JSON.stringify(
				wasteGuideStateDraft.stages,
				null,
				'  ',
			)}`,
		);
	}

	wasteGuideStateDraft.stages.length = stageIndex + 1;
};

export const goBackFromResults = (wasteGuideState: WasteGuideState) => {
	const { stages } = wasteGuideState;

	if (includesResultStage(stages)) {
		return goBackToStage(wasteGuideState, lastQuestionStage);
	}

	return wasteGuideState;
};
