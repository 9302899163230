'use client';

import { classes } from '@/uiPrimitives/base/classes.helpers';
import { box } from '@/uiPrimitives/layout/box';
import { shelf } from '@/uiPrimitives/layout/shelf';
import { textStyle } from '@/uiPrimitives/typography/text';
import { addTestSelector } from '@dop/shared/helpers/testSelector';
import {
	accordionContentPadding,
	delayIconTransitionHover,
} from './Accordion.css';
import { icon } from '@/uiPrimitives/media/icon';
import { chevronIcon } from '@/uiIcons/chevronIcon.css';
import { Collapse } from '../Collapse';
import { ReactNode, useId } from 'react';
import {
	useAccordionItemOpened,
	useDispatchAccordion,
} from './AccordionProvider.client';

export type AccordionItemProps = {
	className?: string;
	/** Provide your own \<h2\>, \<h3\> or \<h4\> with your own `textBlock` */
	summarySlot: ReactNode;
	children: ReactNode;
	id: number | string;
};
export const AccordionItem = ({
	className,
	summarySlot,
	children,
	id,
}: AccordionItemProps) => {
	const isOpened = useAccordionItemOpened(id);
	const dispatchAccordion = useDispatchAccordion();

	const htmlId = useId();

	return (
		<div key={id} className={className}>
			<button
				aria-controls={htmlId}
				aria-expanded={isOpened}
				onClick={() => {
					dispatchAccordion({
						type: 'toggle',
						id,
					});
				}}
				className={classes(
					shelf({
						gap: '-1 | small',
						gridTemplateColumns: 'minmax(0, 1fr) auto',
						alignItems: 'baseline',
					}),
					box({
						paddingBlock: '1 | h3',
						inlineSize: '100%',
					}),
					textStyle({
						color: 'positiveBlue',
						fontWeight: isOpened ? 'bold' : undefined,
					}),
				)}
				{...addTestSelector(`uiAccordionItem-${id}`)}
			>
				{summarySlot}
				<i
					className={classes(
						delayIconTransitionHover,
						icon({
							icon: chevronIcon,
							adjustIcon: 'rotate90deg',
							flip: isOpened ? 'horizontal:hover' : 'horizontalNone:hover',
							blockSize: '1ex',
						}),
					)}
				/>
			</button>
			<Collapse id={htmlId} isOpened={isOpened}>
				<div
					className={classes(accordionContentPadding)}
					{...addTestSelector(`uiAccordionContent-${id}`)}
				>
					{children}
				</div>
			</Collapse>
		</div>
	);
};
