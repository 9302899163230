'use client';

import { closeIcon } from '@/uiIcons/closeIcon.css';
import { classes } from '@/uiPrimitives/base/classes.helpers';
import { box } from '@/uiPrimitives/layout/box';
import { pageGrid } from '@/uiPrimitives/layout/pageGrid/pageGrid';
import { placement } from '@/uiPrimitives/layout/placement.css';
import { shelf } from '@/uiPrimitives/layout/shelf';
import { icon } from '@/uiPrimitives/media/icon';
import { useState } from 'react';
import { addClosedBannerToCookie } from './alertBannerCookies';
import { Translation } from '@/globals/translate/translations';
import { addTestSelector } from '@dop/shared/helpers/testSelector';
import { useDisablePageBehindMenu } from '../pageHeader/mainMenu/MainMenu.client';
import { zIndex } from '@/uiPrimitives/layout/zIndex.css';

export const AlertBannerClient = ({
	children,
	alertID,
	closeAlertTranslation,
}: {
	children: React.ReactNode;
	alertID: string;
	closeAlertTranslation: Translation<'Close alert'>;
}) => {
	const [showing, setShowing] = useState(true);
	const { className, ...disabledBehindMenuProps } = useDisablePageBehindMenu();

	if (!showing) return null;

	return (
		<aside
			data-nosnippet
			{...disabledBehindMenuProps}
			className={classes(
				className,
				box({
					backgroundColor: 'negativeYellow',
				}),
				pageGrid({}),
				zIndex({ zIndex: 'brandBar' }),
			)}
			{...addTestSelector('uiAlertBanner')}
		>
			<div
				className={classes(
					shelf({
						gridTemplateColumns: 'minmax(0, 1fr) auto',
						gap: '3 | h1',
					}),
				)}
			>
				{children}
				<button
					className={classes(
						placement({
							alignSelf: 'end',
							justifySelf: 'end',
						}),
						box({
							backgroundColor: 'positiveBlue',
							padding: '-1 | small',
						}),
					)}
					aria-label={closeAlertTranslation}
					onClick={() => {
						addClosedBannerToCookie(alertID);
						setShowing(false);
					}}
					{...addTestSelector('uiAlertBannerCloseButton')}
				>
					<i
						className={classes(
							icon({
								icon: closeIcon,
								inlineSize: '0 | p',
								blockSize: '0 | p',
							}),
						)}
					/>
				</button>
			</div>
		</aside>
	);
};
