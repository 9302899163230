import { convertParamsObjectToString } from '@dop/shared/helpers/urlHelpers';

import {
	ANSWERS,
	WasteGuidePageData,
	WasteGuideState,
} from '../wasteGuide.types';
import { objectEntries } from 'tsafe';
import { DOPRouter } from '@/globals/routing/DOPRouter';

// these params will be used as keys for the URL parameters
export const PARAMS = {
	COMPLETED: 'c',
	LOT_SIZE: 'l',
	WASTE_VOLUME_FOR_MEDIUM_LOT: 'vm',
	WASTE_VOLUME_FOR_LARGE_LOT: 'vl',
	INCIDENTAL_WASTE: 'i',
	FREQUENT_WASTE_FOR_MEDIUM_LOT: 'fm',
	FREQUENT_WASTE_FOR_LARGE_LOT: 'fl',
	// the always-separate waste answers will be mapped to their answer (either sometimes or often; we don't save 'never' since that is the logical answer if the waste-type is neither sometimes or often)
	ALWAYS_SEPARATE_WASTE_SOMETIMES: 'as',
	ALWAYS_SEPARATE_WASTE_OFTEN: 'ao',
} as const;

// keep track of multi-value params to know which are strings and which are arrays of strings
export const MULTIPLE_VALUES_PARAMS = [
	PARAMS.INCIDENTAL_WASTE,
	PARAMS.FREQUENT_WASTE_FOR_LARGE_LOT,
	PARAMS.ALWAYS_SEPARATE_WASTE_SOMETIMES,
	PARAMS.ALWAYS_SEPARATE_WASTE_OFTEN,
] as const;

// reduce answers for always-separate wastes into slug-arrays over 3 answer-types (never, sometimes, always)
export const reduceAlwaysSeparateWasteAnswersIntoParamArray = (
	alwaysSeparateWaste: WasteGuideState['alwaysSeparateWaste'],
) =>
	objectEntries(alwaysSeparateWaste).reduce(
		(acc, [slug, answer]) => {
			if (answer && slug) {
				return {
					...acc,
					[answer]: acc[answer] ? [...acc[answer], slug] : [slug],
				};
			}
			return acc;
		},
		{} as { [Key in string]: string[] },
	);

export const getSelectionParameters = (
	wasteGuideState: WasteGuideState,
	currentParamsStr = '',
) => {
	const alwaysSeparateWaste = reduceAlwaysSeparateWasteAnswersIntoParamArray(
		wasteGuideState.alwaysSeparateWaste,
	);

	const params = {
		[PARAMS.COMPLETED]: 1,
		[PARAMS.LOT_SIZE]: wasteGuideState.lotSize,
		[PARAMS.WASTE_VOLUME_FOR_MEDIUM_LOT]:
			wasteGuideState.wasteVolumeForMediumLot,

		[PARAMS.WASTE_VOLUME_FOR_LARGE_LOT]: wasteGuideState.wasteVolumeForLargeLot,

		// this ia a radio-box with one value:
		[PARAMS.FREQUENT_WASTE_FOR_MEDIUM_LOT]:
			wasteGuideState.frequentWasteForMediumCompany,

		// the following questions are all checkboxes (multiple answers possible)
		[PARAMS.INCIDENTAL_WASTE]: [...wasteGuideState.incidentalWaste],
		[PARAMS.FREQUENT_WASTE_FOR_LARGE_LOT]: [
			...wasteGuideState.frequentWasteForLargeCompany,
		],
		[PARAMS.ALWAYS_SEPARATE_WASTE_SOMETIMES]:
			alwaysSeparateWaste[ANSWERS.ALWAYS_SEPARATE_WASTE.SOMETIMES],
		[PARAMS.ALWAYS_SEPARATE_WASTE_OFTEN]:
			alwaysSeparateWaste[ANSWERS.ALWAYS_SEPARATE_WASTE.OFTEN],

		// we don't put 'never' answers for always-separate-waste in the params
	};

	return convertParamsObjectToString(params, currentParamsStr);
};

export const setWasteGuideParams = (
	wasteGuideState: WasteGuideState,
	router: DOPRouter,
) => {
	const paramsStr = getSelectionParameters(
		wasteGuideState,
		window.location.search,
	);

	router.replace(`${window.location.pathname}?${paramsStr}`, { scroll: false });
};

export const clearWasteGuideParams = (router: DOPRouter) => {
	const params = new URLSearchParams(window.location.search);
	Object.values(PARAMS).forEach((name) => {
		params.delete(name);
	});
	const paramsStr = params.toString();

	// take off any wasteguide-specific params (and keep any non-specific ones just in case)
	router.replace(`${window.location.pathname}?${paramsStr}`, { scroll: false });
};

export const parseWasteGuideParams = () => {
	const params = new URLSearchParams(window.location.search);

	const acc: {
		-readonly [Key in keyof typeof PARAMS]?: string | string[];
	} = {};
	for (const [key, name] of objectEntries(PARAMS)) {
		if (
			MULTIPLE_VALUES_PARAMS.includes(
				name as (typeof MULTIPLE_VALUES_PARAMS)[number],
			)
		) {
			acc[key] = params.getAll(name) ?? undefined;
		} else {
			acc[key] = params.get(name) ?? undefined;
		}
	}

	return acc;
};

// merge the answer-groups for 'sometimes' and 'often' into one always-separate waste array with the possible values includng 'never'
export const mergeAlwaysSeparateAnswerGroups = (
	{
		ALWAYS_SEPARATE_WASTE_SOMETIMES: sometimesAnswers,
		ALWAYS_SEPARATE_WASTE_OFTEN: oftenAnswers,
		...restAnswers
	}: ReturnType<typeof parseWasteGuideParams>,
	alwaysSeparateWasteAnswers: WasteGuidePageData['wasteAnswers']['alwaysSeparateWasteAnswers'],
) => {
	// get all slugs for alwaysSeparateWaste
	const alwaysSeparateWasteSlugs = alwaysSeparateWasteAnswers.flatMap(
		(item) => {
			return item.wastes;
		},
	);

	// set correct answer for each slug
	const alwaysSeparateWasteParams = alwaysSeparateWasteSlugs.reduce(
		(acc, slug) => {
			if (sometimesAnswers?.includes(slug)) {
				return { ...acc, [slug]: ANSWERS.ALWAYS_SEPARATE_WASTE.SOMETIMES };
			} else if (oftenAnswers?.includes(slug)) {
				return { ...acc, [slug]: ANSWERS.ALWAYS_SEPARATE_WASTE.OFTEN };
			} else {
				return { ...acc, [slug]: ANSWERS.ALWAYS_SEPARATE_WASTE.NEVER };
			}
		},
		{} as Record<string, string>,
	);

	return {
		...restAnswers,
		ALWAYS_SEPARATE_WASTE: alwaysSeparateWasteParams,
	};
};
