import React from 'react';
import styled from 'styled-components';

import { IconInnovatieCircle } from '@dop/ui-icons/svg/innovatieCircle';
import { Markup } from '@dop/shared/components/markup/Markup';
import { hemelblauw } from 'moduleAlias/project/src/styles/colors';
import { Heading } from 'moduleAlias/project/src/components/display/heading';
import { translate } from '@dop/shared/translate/translate';
import {
	FilledBoxButton,
	FilledBoxIcon,
	FilledBoxText,
} from '@dop/ui-composites/link/FilledBoxLink';
import { IconChevronRightBold } from '@dop/ui-icons/svg/chevronRightBold';

import { TextButton } from './ui/TextButton';
import { useAsidePanel, AsidePanelContainer } from './ui/asidePanel';
import { WasteGuideIntroductionAsidePanel } from './WasteGuideIntroductionAsidePanel';
import {
	WasteGuidePageData,
	WasteGuideSummary,
	wasteCategoryKeyMapping,
} from './wasteGuide.types';
import { HTMLContent } from '@/sections/markup/Markup.types';
import { objectKeys } from 'tsafe';

const IntroductionAside = styled.aside`
	background-color: ${hemelblauw.lighter};
	padding-right: 1.5rem;
	display: flex;
	flex-direction: row;
	margin: 1rem 0 2rem;
`;

const SummaryBlockStyled = styled.footer`
	margin: 2rem 0;
	padding: 1rem;
	grid-area: summary;
	border: 2px solid ${hemelblauw.default};
	background-color: ${hemelblauw.lightest};
`;

const AsideButton = styled(TextButton)`
	display: block;
	margin: 0.5rem 0 1rem;
`;

const SummaryAsidePanel = ({
	summaryKey,
	summary,
	wasteAnswers,
	wastesMap,
}: {
	summaryKey: keyof typeof wasteCategoryKeyMapping;
	summary: WasteGuideSummary;
	wasteAnswers: WasteGuidePageData['wasteAnswers'];
	wastesMap: WasteGuidePageData['wastes'];
}) => {
	const { buttonProps, panelProps } =
		useAsidePanel<HTMLButtonElement>(summaryKey);

	const title = summary.aside.title;

	return (
		<>
			<AsidePanelContainer {...panelProps} aria-label={title}>
				<WasteGuideIntroductionAsidePanel
					title={title}
					wasteAnswers={wasteAnswers}
					answersKeyArray={wasteCategoryKeyMapping[summaryKey].answers}
					wasteTypeTitleSuffixKey={
						wasteCategoryKeyMapping[summaryKey].titleSuffixKey
					}
					summaryKey={summaryKey}
					wastesMap={wastesMap}
				/>
			</AsidePanelContainer>

			<AsideButton {...buttonProps}>{summary.linkText}</AsideButton>
		</>
	);
};

const SummaryBlock = ({
	summaries,
	wasteAnswers,
	wastesMap,
}: {
	summaries: WasteGuidePageData['startScreen']['summaries'];
	wasteAnswers: WasteGuidePageData['wasteAnswers'];
	wastesMap: WasteGuidePageData['wastes'];
}) => {
	return (
		<SummaryBlockStyled>
			<Heading
				level={2}
				styleLevel={2}
				css={`
					margin-top: 0;
					color: ${hemelblauw.default};
				`}
				data-ui-test="uiHeaderAfvalscheidenSamenvatting"
			>
				{summaries.title || translate('summary')}
			</Heading>

			<p>{summaries.description}</p>
			{objectKeys(wasteCategoryKeyMapping).map((summaryKey) => {
				const summary = summaries[summaryKey];
				return (
					<React.Fragment key={summaryKey}>
						<Heading
							level={3}
							styleLevel={4}
							css={`
								margin-top: 2.5rem;
							`}
						>
							{summary.title}
						</Heading>
						<Markup
							css={`
								margin-bottom: 1rem;
							`}
							html={summary.text.html}
						/>
						<SummaryAsidePanel
							summaryKey={summaryKey}
							summary={summary}
							wasteAnswers={wasteAnswers}
							wastesMap={wastesMap}
						/>
					</React.Fragment>
				);
			})}
		</SummaryBlockStyled>
	);
};

const IntroductionAsideTip = ({ introAside }: { introAside: HTMLContent }) => {
	return (
		<IntroductionAside>
			<IconInnovatieCircle
				role="img"
				aria-label="tip"
				css={`
					flex: 0 0 auto;
					margin-right: 0.5rem;
					width: 2.5rem;
					height: 2.5rem;
					padding: 0.3rem;
					fill: ${hemelblauw.grijscontrast};
				`}
			/>

			<Markup
				css={`
					flex: auto;
					padding-top: 0.2rem;
					padding-bottom: 0.3rem;

					&:after {
						content: '';
						display: block;
						height: 0;
						margin-top: 1rem;
						margin-bottom: -1rem;
					}
				`}
				html={introAside.html}
			/>
		</IntroductionAside>
	);
};

export const WasteGuideIntroduction = ({
	firstQuestionFocusRef,
	firstQuestionScrollRef,
	stages,
	wasteGuideStarted,
	startScreen,
	wasteAnswers,
	wastesMap,
}: {
	firstQuestionFocusRef: React.RefObject<HTMLElement>;
	firstQuestionScrollRef: React.RefObject<HTMLElement>;
	stages: Array<string>;
	wasteGuideStarted: () => void;
	startScreen: WasteGuidePageData['startScreen'];
	wasteAnswers: WasteGuidePageData['wasteAnswers'];
	wastesMap: WasteGuidePageData['wastes'];
}) => {
	const startWasteGuide = () => {
		if (stages.length > 0) {
			// reset focus to question 1 if already started

			firstQuestionFocusRef.current?.focus({
				preventScroll: firstQuestionScrollRef.current != null,
			});

			firstQuestionScrollRef.current?.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		} else {
			// start guide
			wasteGuideStarted();
		}
	};

	return (
		<>
			{startScreen.intro != null && (
				<div>
					<Markup html={startScreen.intro.html} />
				</div>
			)}
			{startScreen.asideText != null && (
				<IntroductionAsideTip introAside={startScreen.asideText} />
			)}
			<FilledBoxButton
				data-ui-test="startWasteguideButton"
				onClick={startWasteGuide}
			>
				<FilledBoxText>{startScreen.ctaButtonText}</FilledBoxText>
				<FilledBoxIcon as={IconChevronRightBold} />
			</FilledBoxButton>
			<SummaryBlock
				summaries={startScreen.summaries}
				wasteAnswers={wasteAnswers}
				wastesMap={wastesMap}
			/>
		</>
	);
};
