import React from 'react';

import { Dialog } from '@dop/ui-primitives/positioning/Dialog';
import { Box } from '@dop/ui-primitives/planeDivision/Box';
import { Grid } from '@dop/ui-primitives/layout/Grid';
import { toRootCap } from '@dop/shared/styleHelpers/toRootCap';
import { Scroll } from '@dop/ui-primitives/positioning/Scroll';
import { addTestSelector } from '@dop/shared/helpers/testSelector';

import {
	SlotElement,
	RequiredSlotElement,
} from '../pageLayout/pageLayout.types';
import { CloseButton } from '../buttons/CloseButton';

type SideOverlayLayoutProps = {
	closeSlot: RequiredSlotElement;
	contentSlot: RequiredSlotElement;
	confirmSlot?: SlotElement;
};

const SideOverlayLayout = ({
	closeSlot,
	contentSlot,
	confirmSlot,
}: SideOverlayLayoutProps) => {
	return (
		<Grid
			$columns={`auto minmax(0, 1fr)`}
			$rows={`${toRootCap(3.5)} 1fr  ${
				confirmSlot ? `${toRootCap(2)} auto` : ''
			}`}
			$blockSize="100%"
			$areas={`
				". content" 
				"close content"
				${confirmSlot ? `". ." "confirm confirm"` : ''}
			`}
			$gap={[0, 2.5]}
		>
			{React.cloneElement(closeSlot, { $gridArea: 'close' })}
			{React.cloneElement(contentSlot, { $gridArea: 'content' })}
			{confirmSlot && React.cloneElement(confirmSlot, { $gridArea: 'confirm' })}
		</Grid>
	);
};

export type SideOverlayProps = {
	contentSlot: RequiredSlotElement;
	confirmSlot?: SlotElement;
	onClose: (event: Event | React.MouseEvent) => void;
	opened: boolean;
	id?: string;
};

/**
 * SideOverlay is a component you can use to pop-up from the right side of the window. It comes with a close-button,
 * but since it uses the <dialog> element under the hood, clicking outside the dialog, or pressing `ESC` will close it as well.
 *
 * The content can be set via the contentSlot property.
 * For some filter-options in a sideOverlay (e.g. the events-page) we can also use the optional confirmSlot, that presents a bar
 * at the bottom, for any confirm-button stuff.
 */
export const SideOverlay = ({
	contentSlot,
	confirmSlot,
	onClose,
	opened = false,
	id,
}: SideOverlayProps) => {
	return opened ? (
		<Dialog
			id={id}
			opened={opened}
			onClose={onClose}
			onCancel={onClose}
			$margin={[0, 0, 0, 'auto']}
			$blockSize="100%"
			$inlineSize={46}
			$minBlockSize="100%"
		>
			<Box
				$borderInlineStart={['3px', 'solid', ['positive', 'normal']]}
				$backgroundColor={['negative', 'normal']}
				$blockSize="100%"
				{...addTestSelector('uiSideOverlay')}
			>
				<SideOverlayLayout
					closeSlot={
						<CloseButton
							onClick={onClose}
							{...addTestSelector('uiSideOverlayCloseButton')}
						/>
					}
					contentSlot={
						<Scroll $overflowY="auto" role="document" $paddingBlock={3.5}>
							{contentSlot}
						</Scroll>
					}
					confirmSlot={confirmSlot}
				/>
			</Box>
		</Dialog>
	) : null;
};
