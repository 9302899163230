import { classes } from '../base/classes.helpers';
import {
	boxSprinkles,
	boxBase,
	borderInlineCollapse,
	borderBlockCollapse,
} from './box.css';

export type BoxProps = Parameters<typeof boxSprinkles>[0];
/**
 * Primitive for visual plain division, not layout
 *
 * Responsibilities:
 *
 * 1. Visually distinguish a box from its surroundings by giving it a different background color.
 * 2. Visually distinguish a box from its surroundings by giving it a border.
 * 3. Visually distinguish a box from its surroundings by giving it a shadow.
 * 4. Padding inside the box so elements can be placed inside the box without hitting the side of the box.
 */
export const box = ({
	borderCollapse,
	...props
}: BoxProps & { borderCollapse?: 'inline' | 'block' }) => {
	return classes(
		boxBase,
		boxSprinkles(props),
		borderCollapse === 'inline' && borderInlineCollapse,
		borderCollapse === 'block' && borderBlockCollapse,
	);
};
