import { config } from 'moduleAlias/config';

import { negate, isNotNumber } from './functional';

export const isString = (value) => typeof value === 'string';

export const isNotString = negate(isString);

export const isFilledString = (value) => isString(value) && value.length > 0;

export const isNotFilledString = negate(isFilledString);

export const stringOnly = (value) => (isString(value) ? value : undefined);

export const toLowerCase = (value) =>
	isString(value) ? value.toLowerCase() : value;

export const capitalizeFirstLetter = (value) =>
	isString(value) ? `${value.charAt(0).toUpperCase()}${value.slice(1)}` : value;

export const kebabCase = (text = '') => {
	return (
		text
			.toLowerCase()
			// Replace all non alphanumeric characters with a dash
			.replace(/[^[a-zA-Z0-9]+/g, '-')
			// Trim dashes fron start
			.replace(/^-+/, '')
			// Trim dashes fron end
			.replace(/-+$/, '')
	);
};

export const kebabToConstantCase = (string) => {
	return string.toUpperCase().replace(/-/g, '_');
};

export const appendIfMissing = (value, appendCharacter) => {
	if (isNotString(value)) {
		return value;
	}

	return value.charAt(value.length - 1) === appendCharacter
		? value
		: `${value}${appendCharacter}`;
};

/**
 * This function will camelcase a string and remove all spaces. ie. 'Info en Advies' becomes 'infoEnAdvies'
 * @param {string} str
 */
export const camelize = (str = '') =>
	str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
		if (Number(match) === 0) {
			return '';
		}
		return index === 0 ? match.toLowerCase() : match.toUpperCase();
	});
export const bracketize = (string) => `(${string})`;

const chunkNumberString = (chunkSize) => (string) => {
	const memo = [];

	for (
		let restString = string;
		restString.length > 0;
		restString = restString.slice(0, -chunkSize)
	) {
		memo.unshift(restString.slice(-chunkSize));
	}

	return memo;
};

export const formatNumber = ({ decimals = 0, number }) => {
	if (isNotNumber(number)) {
		return number;
	}

	const {
		thousandSeperationFromLength,
		chunkSize,
		thousandsSeparatorGlyph,
		decimalGlyph,
	} = config.numberFormatting;

	const numberString = String(number);

	const [integerString, decimalString = ''] = numberString.split('.');

	const formattedDecimals = decimalString
		.slice(0, decimals)
		.padEnd(decimals, '0');

	const formattedIntegers =
		integerString.length <= thousandSeperationFromLength
			? integerString
			: chunkNumberString(chunkSize)(integerString).join(
					thousandsSeparatorGlyph,
				);

	return formattedDecimals.length > 0
		? `${formattedIntegers}${decimalGlyph}${formattedDecimals}`
		: formattedIntegers;
};

/*
 * Splits a string on 1 or more newlines, ignores surrounding whitespaces.
 */
export const splitOnNewline = (str) => str.split(/\s*(?:\r\n|\n|\r)+\s*/g);

/*
 * Splits a string on 2 or more newlines, ignores (surrounding) whitespaces.
 */
export const splitOnNewlines = (str) =>
	str.split(/\s*(?:\r\n|\n|\r)\s*(?:\r\n|\n|\r)+\s*/g);

/**
 * Unicode-encoding for a single character in JSON contexts.
 * @param {string} charStr single-character string.
 * @return {string} unicode escaped character.
 * @private
 */
const JSON_ALLOWED = /[^\x40\x20\x22,\-\.0-9:A-Z\[\x5C\]_a-z{}\/\r\n\t]/g;
export const escapeJSON = (json) => {
	const res = JSON.stringify(json, null, '\t');
	return String(res).replace(
		JSON_ALLOWED,
		(c) => '\\u' + ('0000' + c.charCodeAt(0).toString(16)).slice(-4), // eslint-disable-line no-magic-numbers
	);
};

export const joinArrayToString = (content) => {
	if (Array.isArray(content)) {
		// eslint-disable-next-line no-param-reassign
		content = content.join('\n');
	}
	return content;
};
