import "../../../.yarn/__virtual__/@vanilla-extract-webpack-plugin-virtual-4e555a71a2/0/cache/@vanilla-extract-webpack-plugin-npm-2.3.14-2cf92976c3-e7193ab6e5.zip/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2FuiPrimitives%2Fbase%2Flayers.css.tsx.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA5WWUW7DIAxA%2F3eKnSAX6E%2B1apoiTdPUGxDipmgEI0PSZqefknUrVMGQT5zHswMOYa%2FFBPSsoRNyOoIDv3va%2F8YoGgXEEUagxyfBtPnpexxshIP%2FwaA%2BSfXKqxFcEKwlmmB4wN6iUz5gGo3y6wPbIORAeoXmHjihHO4jHIEuFEnC7FWyrsoS2sQkT8I4NadNSystJhw842aJQFG5syBo8yYWDIVWdPBGqkTJo6GUEH0ZKQW1hel5NHonpbmNzGOhTBAJ05X4WDLaREmotWg0HAS1icaKd7NkRpgCpRzsVGDmwFD4XZsWrgVCDlxrvNpDv6H50vhaWxXK83gob7BkHZJUtAjInR3xArBo1F6DBepItUe8FDVXno%2F1zSZ5hl6t%2FIB66M2m4tkpK%2FVvSZGfsCTwk8WOhD2zH16OelBVHq7%2BZf7ZlUlz%2FJq%2BNloZ9ojbMGFJ0EOrBCdkgLugUr3gj94sFsjc2OVVSSisSvJfbI56UOVXv4xdtMp4ICHnCGfMYoNXWvlEi94eVvON6qTxwlMnnfof%2FBEWl3peRzD%2B4fYW38puIbzufgDLZnpNrAoAAA%3D%3D%22%7D"
import "../../../.yarn/__virtual__/@vanilla-extract-webpack-plugin-virtual-4e555a71a2/0/cache/@vanilla-extract-webpack-plugin-npm-2.3.14-2cf92976c3-e7193ab6e5.zip/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2FuiPrimitives%2Fbase%2Ftransition.css.tsx.vanilla.css%22%2C%22source%22%3A%22QGxheWVyIGJhc2U7CkBsYXllciB1aVByaW1pdGl2ZXMudHJhbnNpdGlvbjsKOnJvb3QgewogIC0tXzFpdHowZ3kwOiAxMDBtcyBlYXNlLWluLW91dDsKICAtLV8xaXR6MGd5MTogMzAwbXMgZWFzZS1vdXQ7CiAgLS1fMWl0ejBneTI6IDUwMG1zIGVhc2Utb3V0Owp9CkBsYXllciBiYXNlIHsKICAuXzFpdHowZ3loIHsKICAgIC0tXzFpdHowZ3kzOiAwbXM7CiAgICAtLV8xaXR6MGd5NDogMG1zOwogICAgLS1fMWl0ejBneTU6IDBtczsKICAgIC0tXzFpdHowZ3k2OiAwbXM7CiAgICAtLV8xaXR6MGd5NzogMG1zOwogICAgLS1fMWl0ejBneTg6IDBtczsKICAgIC0tXzFpdHowZ3k5OiAwbXM7CiAgICAtLV8xaXR6MGd5YTogMG1zOwogICAgLS1fMWl0ejBneWI6IDBtczsKICAgIC0tXzFpdHowZ3ljOiAwbXM7CiAgICAtLV8xaXR6MGd5ZDogMG1zOwogICAgLS1fMWl0ejBneWU6IDBtczsKICAgIC0tXzFpdHowZ3lmOiAwbXM7CiAgICAtLV8xaXR6MGd5ZzogMG1zOwogIH0KfQpAbGF5ZXIgdWlQcmltaXRpdmVzLnRyYW5zaXRpb24gewogIEBtZWRpYSAocHJlZmVycy1yZWR1Y2VkLW1vdGlvbjogbm8tcHJlZmVyZW5jZSkgewogICAgLl8xaXR6MGd5aCB7CiAgICAgIHRyYW5zaXRpb246IAoJCQkJCQljb2xvciB2YXIoLS1fMWl0ejBneTMpIHZhcigtLV8xaXR6MGd5NCksCgkJCQkJCWdyaWQtdGVtcGxhdGUtcm93cyB2YXIoLS1fMWl0ejBneTUpIHZhcigtLV8xaXR6MGd5NiksCgkJCQkJCWJhY2tncm91bmQtY29sb3IgdmFyKC0tXzFpdHowZ3k3KSB2YXIoLS1fMWl0ejBneTgpLAoJCQkJCQlib3JkZXItY29sb3IgdmFyKC0tXzFpdHowZ3k5KSB2YXIoLS1fMWl0ejBneWEpLAoJCQkJCQlvcGFjaXR5IHZhcigtLV8xaXR6MGd5YikgdmFyKC0tXzFpdHowZ3ljKSwKCQkJCQkJdHJhbnNsYXRlIHZhcigtLV8xaXR6MGd5ZCkgdmFyKC0tXzFpdHowZ3llKSwKCQkJCQkJcm90YXRlIHZhcigtLV8xaXR6MGd5ZikgdmFyKC0tXzFpdHowZ3lnKQoJCQkJCTsKICAgIH0KICB9Cn0%3D%22%7D"
export var backgroundColorTransition = 'var(--_1itz0gy7)';
export var backgroundColorTransitionDelay = 'var(--_1itz0gy8)';
export var borderColorTransition = 'var(--_1itz0gy9)';
export var borderColorTransitionDelay = 'var(--_1itz0gya)';
export var colorTransition = 'var(--_1itz0gy3)';
export var colorTransitionDelay = 'var(--_1itz0gy4)';
export var fastTransition = 'var(--_1itz0gy0)';
export var gridTemplateRowsTransition = 'var(--_1itz0gy5)';
export var gridTemplateRowsTransitionDelay = 'var(--_1itz0gy6)';
export var mediumTransition = 'var(--_1itz0gy1)';
export var opacityTransition = 'var(--_1itz0gyb)';
export var opacityTransitionDelay = 'var(--_1itz0gyc)';
export var rotateTransition = 'var(--_1itz0gyf)';
export var rotateTransitionDelay = 'var(--_1itz0gyg)';
export var slowTransition = 'var(--_1itz0gy2)';
export var transition = '_1itz0gyh';
export var translateTransition = 'var(--_1itz0gyd)';
export var translateTransitionDelay = 'var(--_1itz0gye)';