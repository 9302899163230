import { ComponentPropsWithoutRef, ReactElement, ReactNode } from 'react';

import { chevronIcon } from '@/uiIcons/chevronIcon.css';
import { classes } from '@/uiPrimitives/base/classes.helpers';
import { box } from '@/uiPrimitives/layout/box';
import { icon } from '@/uiPrimitives/media/icon';
import { textBlock, textStyle } from '@/uiPrimitives/typography/text';
import { shelf } from '@/uiPrimitives/layout/shelf';
import { occupy } from '@/uiPrimitives/layout/occupy';

import { DOPLink } from './DOPLink';
import { indentSize } from '@/uiPrimitives/layout/indentSize.definitions';
import { translateInline } from '@/globals/translate/translateInline';

type IconLinkContentProps = {
	icon: ReactElement;
	styling?: {
		iconSpace?: 'fit-content' | typeof indentSize;
		gap?: Parameters<typeof box>[0]['paddingInlineEnd'];
		color?: Parameters<typeof textStyle>[0]['color'];
	};
};

export const IconLinkContent = ({
	icon,
	children,
	styling: {
		iconSpace = indentSize,
		gap = '-2 | smaller',
		color = 'positiveBlue',
	} = {},
}: IconLinkContentProps & { children: ReactNode }) => {
	return (
		<span
			className={classes(
				shelf({
					alignItems: 'start',
				}),
				textStyle({ color, capLineHeight: 'tight' }),
			)}
		>
			<span
				className={classes(
					occupy({
						blockSize: 'capSize',
						justifyItems: 'start',
						inlineSize: iconSpace,
					}),
					box({ paddingInlineEnd: gap }),
				)}
			>
				{icon}
			</span>
			<span className={textBlock({})}>{children}</span>
		</span>
	);
};

export const AfterLinkIconContent = ({
	icon,
	children,
	styling: {
		iconSpace = indentSize,
		gap = '-2 | smaller',
		color = 'positiveBlue',
	} = {},
}: IconLinkContentProps & { children: ReactNode }) => {
	return (
		<span
			className={classes(
				shelf({
					alignItems: 'start',
				}),
				textStyle({ color, capLineHeight: 'tight' }),
			)}
		>
			<span className={classes(textBlock({}), box({ paddingInlineEnd: gap }))}>
				{children}
			</span>
			<span
				className={classes(
					occupy({
						blockSize: 'capSize',
						justifyItems: 'center',
						inlineSize: iconSpace,
					}),
				)}
			>
				{icon}
			</span>
		</span>
	);
};

export const IconLink = ({
	icon,
	children,
	className,
	styling,
	...props
}: ComponentPropsWithoutRef<typeof DOPLink> & IconLinkContentProps) => {
	return (
		<DOPLink
			{...props}
			className={classes(className, box({ inlineSize: 'fit-content' }))}
		>
			<IconLinkContent icon={icon} styling={styling}>
				{children}
			</IconLinkContent>
		</DOPLink>
	);
};
export const IconAfterLink = ({
	icon,
	children,
	className,
	styling,
	...props
}: ComponentPropsWithoutRef<typeof DOPLink> & IconLinkContentProps) => {
	return (
		<DOPLink
			{...props}
			className={classes(className, box({ inlineSize: 'fit-content' }))}
		>
			<AfterLinkIconContent icon={icon} styling={styling}>
				{children}
			</AfterLinkIconContent>
		</DOPLink>
	);
};

export const ChevronLink = (
	props: Omit<ComponentPropsWithoutRef<typeof IconLink>, 'icon'>,
) => {
	return (
		<IconLink
			{...props}
			icon={
				<i
					aria-hidden
					className={icon({
						icon: chevronIcon,
					})}
				/>
			}
		/>
	);
};

export const ChevronBackLink = (
	props: Omit<ComponentPropsWithoutRef<typeof IconLink>, 'icon'>,
) => {
	return (
		<IconLink
			{...props}
			icon={
				<i
					role="img"
					aria-label={translateInline({ en: 'Back', nl: 'Terug' })}
					className={icon({
						icon: chevronIcon,
						adjustIcon: 'flipHorizontal',
					})}
				/>
			}
		/>
	);
};

export const ChevronAfterLink = (
	props: Omit<ComponentPropsWithoutRef<typeof IconAfterLink>, 'icon'>,
) => {
	return (
		<IconAfterLink
			{...props}
			icon={
				<i
					aria-hidden
					className={icon({
						icon: chevronIcon,
					})}
				/>
			}
		/>
	);
};
