import { pushEvent } from '../pushEvent';

export const pushMopinionEvent = ({
	url,
	formName,
	action,
	externalLink,
}: {
	url: string;
	formName: string;
	action: 'Show popup' | 'Close popup' | 'Click to survey';
	externalLink?: string;
}) => {
	pushEvent({
		events: {
			category: 'interaction.component.mopinionPopup',
			action: `${action} - ${formName}`,
			label: externalLink,
		},
		page: { urlPath: url },
	});
};
